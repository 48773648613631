/* Primary Modal Styles */
.primary_modal_header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary_modal_header_wrapper .modal_title {
  font-size: 17px;
  font-weight: 500;
  color: #000000;
}

/* Modal Contents */
.add_metadata_modal_container .modal-content,
.create_metadata_modal_container .modal-content {
  padding: 1rem;
  border-radius: 10px !important;
  min-height: 150px;
  width: 600px;
}

.primary_modal_action_btns_wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 50px;
}

.primary_modal_action_btns_wrapper button {
  width: 120px;
  height: 30px;
  font-size: 14px;
}

/* Workflow  and steps modals*/
.workflow__step_modal_container.modal-dialog {
  max-width: 900px !important;
}

.workflow__step_modal_container .modal-content {
  padding: 1rem;
  border-radius: 10px !important;
  min-height: 150px;
}
