:root {
  --tablefontsize: clamp(13px, 0.7vw, 20px);
}

.primary_ant_table_wrapper {
  width: 100%;
  overflow: auto;
}

.primary_ant_table_wrapper * {
  outline: none !important;
  color: #5b626b !important;
}

.ant_datagrid_table table {
  min-width: 500px;
  font-size: var(--tablefontsize) !important;
}

.ant_datagrid_table .ant-table-tbody > tr:nth-child(2n + 1) {
  background-color: #f8f9fa !important;
}

.ant_table_title {
  margin: 0 0 10px;
  font-size: 18px;
  font-weight: 500;
  color: #000000;
}

/* Override checkbox accent color */
:where(.css-dev-only-do-not-override-w8mnev).ant-checkbox-checked
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-w8mnev).ant-radio-wrapper
  .ant-radio-checked
  .ant-radio-inner {
  background-color: #626ed4 !important;
}

/* Override Table Head Background color */
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  background-color: transparent;
  font-weight: 500;
}

/* Override th and tr padding and border sty;es */
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper
  .ant-table-tbody
  > tr
  > th,
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper
  .ant-table-tbody
  > tr
  > td,
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper tfoot > tr > th,
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper tfoot > tr > td {
  padding: 0.4rem 0.3rem;
  border-color: #e9ecef;
  border-top-color: rgb(233, 236, 239);
  border-right-color: rgb(233, 236, 239);
  border-bottom-color: rgb(233, 236, 239);
  border-left-color: rgb(233, 236, 239);
}

/* Custom */
.category_metadata_grid_table table {
  min-width: 320px !important;
}
