.app__main {
  /* position: relative; */
}

.page-title-wrapper {
  font-weight: 600;
  font-size: 30px;
  line-height: 43px;
  color: #000000;
  margin-bottom: 17px;
}

button:disabled,
input:disabled,
select:disabled {
  opacity: 0.8;
}

input[type="checkbox"] {
  cursor: pointer;
}

.blur_out_elem {
  opacity: 0.3 !important;
}

textarea {
  resize: none;
}

.dropdown-hover:hover > .dropdown-menu {
  display: inline-block;
}

.dropdown-hover > .dropdown-toggle:active {
  /*Without this, clicking will make it sticky*/
  pointer-events: none;
}

/* Admin Sidebar Link Wrapper */
.sidebar_link_wrapper {
  display: flex !important;
  align-items: center;
  column-gap: 12px !important;
}
