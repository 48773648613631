.primary-table-container {
  overflow-x: auto;
  overflow-y: hidden;
}

/* Table Top */
.primary-table--top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 13px;
}

.primary-table--top-wrapper .table-title {
  font-weight: 600;
  font-size: 24px;
  line-height: 40px;
  color: #000000;
}

.primary-table--top-wrapper .table-top-button button {
  width: 138px;
  height: 45px;
  outline: none;
  border-radius: 10px;
}

.primary-table {
  background-color: white;
  padding: 15px 25px 0;
}

.primary-table tbody tr {
  vertical-align: middle;
}

.below-table-loader-wrapper {
  display: flex;
  justify-content: center;
}
