button {
  outline: none !important;
}

.dashboard-card-body {
  min-width: 680px;
  padding: 0 1.25rem;
  padding-top: 7px;
  overflow-y: hidden;
}

.dashboard-white-card::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

.dashboard-white-card::-webkit-scrollbar {
  height: 7px;
  background-color: #c4c4c4;
}

.dashboard-white-card::-webkit-scrollbar-thumb {
  background-color: #656565;
}

.dashboard-white-card::-webkit-scrollbar-track,
.dashboard-white-card::-webkit-scrollbar,
.dashboard-white-card::-webkit-scrollbar-thumb {
  border-radius: 0px;
}
